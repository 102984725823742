import React, { useState, useEffect } from 'react';
import './App.css';
import Body from './components/App/Body';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-138532822-1');
ReactGA.pageview('/');

export default props => {
  const [cards, setCards] = useState([]);

  useEffect(()=> {
    const data = require('./data/tarot.json');
    setCards(data.tarot_interpretations);
  }, []);

  return (
    <div>
      <div className="container app-wrapper">
        {cards.length > 0 &&
        <Body cards={cards} />
        }
      </div>
    </div>
  );
};
