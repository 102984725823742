import React, { useState, useEffect } from 'react';
import FocusedCard from '../FocusedCard/Container';
import { toTitleCase } from '../../utilities/String';

export default props => {
    const [focusedCard, setFocusedCard] = useState(null);
    const [search, setSearch] = useState('');

    useEffect(() => { drawACard(); }, []);

    const drawACard = () => {
        const card = props.cards[Math.floor(Math.random() * props.cards.length)];
        setFocusedCard(card);
    };

    const renderList = (data) => {
        return data.map((text, i)=>{
            return (<CardListItem key={i} text={text} />);
        });
    }
    
    const renderBadges = (data) => {
        return data.map((text, i)=>{
            return (<CardBadge key={i} text={text} />);
        });
    }

    const searchFilter = (card) => {
        if (search === '') {
            return true;
        }

        const searchTerm = search.toLowerCase();

        return  card.name.toLowerCase().indexOf(searchTerm) !== -1 
        ||      card.suit.toLowerCase().indexOf(searchTerm) !== -1;
    }

    const renderCardButtons = () => {
        return props.cards
        .filter(searchFilter)
        .map((card, i) => (
            <CardButton
                key={i}
                card={card}
                setFocusedCard={() => setFocusedCard(card)}
            />
        ));
    }

    return (
    <div className="row body-wrapper">
        <div className="col-xs-12 col-md-9 card-info-wrapper">
        {props.cards.length > 0 && focusedCard !== null && 
            <div className="row card-info">
                <div className="col-xs-12 col-md-6">
                    <FocusedCard
                        card={focusedCard}
                        renderList={renderList}
                        renderBadges={renderBadges}
                    />
                </div>
                <div className="col-xs-12 col-md-6">
                    <h6>Light Meanings</h6>
                    {renderList(focusedCard.meanings['light'])}
                    <h6>Shadow Meanings</h6>
                    {renderList(focusedCard.meanings['shadow'])}
                </div>
            </div>
        }
        </div>
        {props.cards.length > 0 &&
        <div className="col-xs-12 col-md-3 right-column-wrapper">
            {props.cards.length > 0 &&
            <button onClick={drawACard} className="btn btn-primary col-12">Draw A Card</button>
            }
            <hr />
            {props.cards.length > 0 &&
            <div>
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Search"
                    style={{ marginBottom : 5 + 'px' }}
                    onChange={(e)=>setSearch(e.target.value)}
                />
                <span>{renderCardButtons()}</span>
            </div>
            }
        </div>
        }
    </div>
    )
}

const CardButton = props => (
    <button 
        className="col-12 btn btn-primary btn-sm" 
        style={{ marginBottom : 4 + 'px'}}
        onClick={()=>props.setFocusedCard()}>
        {toTitleCase(props.card.name)}
    </button>
)

const CardBadge = props => (
    <span 
        className="badge badge-primary"
        style={{ marginRight : 10 + 'px' }}>
        {props.text}
    </span>
);

const CardListItem = props => (<p>{props.text}</p>);