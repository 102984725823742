export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function getImageName(card) {
    let rank = '';

    switch(card.rank) {
        case 'knight':
            rank = 'KN';
        break;
        case 1:
            if(card.suit !== 'major'){
                rank = 'A';
            } else {
                rank = 1;
            }
        break;
        case 'page':
        case 'king':
        case 'queen':
            rank = card.rank[0].toUpperCase();
        break;
        default:
            rank = card.rank;
    }

    const suit = card.suit[0].toUpperCase();

    return rank + suit;
}